@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --color-primary: #000000;
  --color-secondary: #ffffff;
}

body {
  background-color: var(--color-secondary);
  color: var(--color-primary);
  margin: 0;
  font-family: 'Figtree', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

